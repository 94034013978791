.kat__services-container {
    border: 1px black;
    border-radius: 30px;
    background-color: #ffff;
}

.kat__services-container-padding {
   padding:  2rem 3rem;
}

.kat__services-title p {
    font-size: 75px;
    font-family: var(--font-secondary);
    font-weight: bold;
    padding-top: 50px;
    padding-bottom: 100px;
}

.kat__services-infocard-flex {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 25px;
}

.kat__services-infocard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000;
    border-radius: 15px;
    padding: 20px 20px 75px;
    width: calc(100% - 20px);
}

.kat__services-infocard-feature {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    align-items: center;
}

.kat__services-infocard-icon img {
    width: 150px;
    height: 150px;
}

.kat__services-infocard-title p {
    font-size: 24px;
    font-family: var(--font-main);
    font-weight: bold;
    color: var(--color-bluetext);
    padding: 0 25px 0 90px;
   
}

.kat__services-infocard-description p {
    font-size: 15px;
    font-family: var(--font-main);
    padding-top: 10px;
}

.kat__services-infocard-button {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 100%;
    display: flex;
    align-self: flex-end;
}

.kat__services-infocard-button button {
  min-width: 50px;
  min-height: 38px;
  background: var(--color-bluetext);
  font-family: var(--font-tertiary);
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 27px;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  border: none;
  border-radius: 50px;
}

.kat__services-infocard-viewall {
    display: flex;
    justify-content: center;
    padding-top: 100px;
}

.kat__services-infocard-viewall button {
    min-height: 50px;
    color: #ffffff;
    background: var(--color-redtext);
    font-family: var(--font-tertiary);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    padding: 0 1rem;
    border-radius: 50px;
    cursor: pointer;
}

@media screen and (max-width: 1130px ) {
    .kat__services-infocard-title p {
        font-size: 24px;
    }

    .kat__services-infocard-feature img { 
        width: 100px;
        height: 100px;
    }
}

@media screen and (max-width: 990px ) {
    .kat__services-infocard-flex {
        display: flex;
        flex-direction: column; 
        justify-content: space-between;
        row-gap: 13px;
    }

    .kat__services-title p  {
        font-size: 50px;
        padding-top: 25px;
        padding-bottom: 50px;
    }
 
}

@media screen and (max-width: 650px ) {

    .kat__services-infocard-feature {
        display: flex;
        flex-direction: column;
    }

    .kat__services-infocard-feature img { 
        width: 70px;
        height: 70px;
    }

    .kat__services-infocard-feature p  {
        font-size: 19px;
        padding: 0 25px 0 25px;
    }

    .kat__services-infocard-description p {
        font-size: 13px;
    }

    .kat__services-infocard-button button {
        min-width: 18px;
        min-height: 18px;
        font-size: 10px; 
    }

    .kat__services-title p  {
        font-size: 25px;
    }

    .kat__services-infocard-viewall button {
        font-size: 11px;
        
    }

    .kat__services-container-padding {
        padding: 1rem 1.5rem 3rem;
    }
}

