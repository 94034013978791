.kat__findus-container {
    display: flex;
}

.kat__findus-availability-box {
    width: 40%;
    background-image: url(../assets/bannerblur.png);
    display: flex;
    flex-direction: column;
    align-items: center;

}

.kat__findus-googlemaps-box {
    flex: 1;
    
}

.kat__findus-googlemaps-box iframe {
    width: 100%;
    height: 100%;
}

.kat__findus-banner {
    align-self: flex-start;
    margin-top: 50px;
    width: 500px;
    height: 207px;
    background-image: url(../assets/testimonialdash.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.kat__findus-banner p {
   font-family: var(--font-tertiary); 
   font-size: 40px;
   font-weight: bold;
   padding-top: 35px;
   padding-left: 50px;
   color: white;
}

.kat__findus-hours-box {
    width: 550px;
    background-color: var(--color-bluetext);
    border-radius: 30px;
    margin-top: 80px;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-left: 40px;
    
    flex:1
}

.kat__findus-hours-box p {
    font-family: var(--font-tertiary);
    color: white;
    font-weight: bold;
    line-height: 130%;
    letter-spacing: 4%;
}

.kat__findus-hours-box-title {
    font-size: 25px;
}

.kat__findus-hours-box-info {
    display: flex;
    flex-direction: column;
}

.kat__findus-hours-box-group {
    display: flex;
    padding-top: 25px;
}

.kat__findus-hours-box-day {
    flex: 1;
}

.kat__findus-hours-box-time {
    align-self: flex-end;
    margin-right: 25px;
}

.kat__findus-hours-box-asterix {
    padding-top: 20px;
    padding-bottom: 15px;
}

.kat__findus-hours-box-address {
    padding-top: 20px;
}

@media screen and (max-width: 1390px) {
    .kat__findus-hours-box {
        width: 400px;
    }

    .kat__findus-banner {
        width: 400px;
    }

    .kat__findus-banner p {
        padding-top: 23px;
    }
}

@media screen and (max-width: 1050px) {
    .kat__findus-hours-box {
        width: 300px;
    }

    .kat__findus-hours-box-title p {
        font-size: 20px;
    }

    .kat__findus-hours-box-group p, .kat__findus-hours-box-info {
        font-size: 12px;
    }

    .kat__findus-banner {
        width: 300px;
    }

    .kat__findus-banner p {
        padding-top: 20px;
        font-size: 25px;
    }
}

@media screen and (max-width: 760px) {
    .kat__findus-container{
        flex-direction: column;
    }

    .kat__findus-availability-box {
        width: 100%;
    }

    .kat__findus-hours-box {
        width: 300px;
        margin-top: 0;
        margin-bottom: 30px;
    }

    .kat__findus-hours-box-title p {
        font-size: 20px;
    }

    .kat__findus-hours-box-group p, .kat__findus-hours-box-info {
        font-size: 12px;
    }

    .kat__findus-banner {
        width: 300px;
    }

    .kat__findus-banner p {
        padding-top: 20px;
        font-size: 25px;
    }
}

@media screen and (max-width: 318px) {
    .kat__findus-banner {
        width: 150px;
    }

    .kat__findus-banner p {
        padding-top: 10px;
        padding-left: 20px;
        font-size: 15px;
    }

    .kat__findus-hours-box {
        width: 100%;
    }

    .kat__findus-hours-box-title p {
        font-size: 15px;
    }

    .kat__findus-hours-box-group p, .kat__findus-hours-box-info {
        font-size: 10px;
    }
}
