.kat__header {
  display: flex;
}

.kat__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
}

.kat__header-content span {
  font-family: var(--font-secondary);
  font-weight: 800;
  font-size: 62px;
  letter-spacing: -0.04em;
}

.kat__text-blue {
  color: var(--color-bluetext);
}

.kat__text-red {
  color: var(--color-redtext);
}

.kat__text-black {
  color: var(--color-text);
}

.kat__header-content p {
  font-family: var(--font-tertiary);
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-text);
  margin-top: 1.5rem;
}

.kat__header-content__input {
  width: 300px;
  margin: 2rem 0 1rem;
  display: flex;
}

.kat__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  padding: 0 1rem;
  background: var(--color-footer);
  border-radius: 5px 0px 0px 5px;
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 20px;
  line-height: 27px;
  color: #3d6184;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.kat__header-content__input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  background: var(--color-bluetext);
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  border: none;
 border-radius: 50px;
}

.kat__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}

.kat__header-content__people img {
  width: 181.79px;
  height: 38px;
}

.kat__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #ffffff;
  text-align: center;
}

.kat__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kat__header-image img {
  width: 100%;
  height: 100%;
}




@media screen and (max-width: 1050px) {
  .kat__header {
    flex-direction: column;
  }
  .kat__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .kat__header h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .kat__header p {
    font-size: 16px;
    line-height: 24px;
  }
  /*Allows p to maintain column*/
  .kat__header-content__people {
    flex-direction: column;
  }
  .kat__header-content__people p {
    margin: 0;
  }

  .kat__header-content__input input,
  .kat__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .kat__header h1 {
    font-size: 36px;
    line-height: 48px;
  }
  .kat__header p {
    font-size: 14px;
    line-height: 24px;
  }
  .kat__header-content__input input,
  .kat__header-content__input button {
    font-size: 12px;
    line-height: 16px;
    
  }

  .kat__header-content__input {
    width: 150px;
  }
}
