.kat__whyus-container {
    border: 1px black;
    border-radius: 30px;
    background-color: #ffff;
}

.kat__whyus-container-padding {
    padding: 2rem 3rem;
}

.kat__whyus-title {
    font-size: 75px;
    font-family: var(--font-secondary);
    font-weight: bold;
    padding-top: 50px;
    padding-bottom: 100px;
}

.kat__whyus-flexbox {
    display: flex;
    justify-content: space-between;
    column-gap: 15%;
}

.kat__whyus-selfie img {
    width: 600px;
    width: 600px;
   
}

.kat__whyus-director {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 3px;
}

.kat__whyus-director-name p {
    font-family: var(--font-tertiary);
    line-height: 130%;
    font-weight: bold;
    font-size: 20px;
    color: var(--color-bluetext);
}

.kat__whyus-director-title {
    font-family: var(--font-tertiary);
    line-height: 130%;
    font-style: italic;
    font-size: 15px;
    padding-top: 15px;
}

.kat__whyus-director-statement {
    padding-top: 30px;
}

.kat__whyus-director-statement > p {
    font-family: var(--font-main);
    font-size: 15px;
    padding-top: 10px;
    color: #737373;
}

.kat__whyus-awards {
    padding-top: 5rem;
    align-items: center;
    
}

.kat__whyus-awardtitle p {
    font-family: var(--font-secondary);
    font-weight: bold;
    font-size: 20px;
    color: var(--color-bluetext);
}

.kat__whyus-awards img {
    padding-top: 1rem;
    width: 50%;
    max-width: 300px;
}

.kat__whyus-awards-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;
}

@media screen and (max-width: 1500px ) {
    .kat__whyus-selfie img {
        width: 80%;
    }

    .kat__whyus-flexbox {
        column-gap: 1%;
    }

}

@media screen and (max-width: 1130px ) {
    .kat__whyus-flexbox {
        flex-direction: column;
    }

    .kat__whyus-selfie {
        margin-bottom: 10px;
    }
}    

@media screen and (max-width: 650px ) {
    .kat__whyus-title {
        padding-top: 25px;
        padding-bottom: 50px;
    }
    
    .kat__whyus-title p {
        font-size: 25px;
    }

    .kat__whyus-director-statement p {
        font-size: 13px;
    }

    .kat__whyus-awardtitle p {
        font-size: 15px;
    }

    .kat__whyus-container-padding {
        padding: 1rem 1.5rem 3rem;
    }
}