.kat__footer {
    /* In combination with FooterOverlay.css: kat__footerOverlay correctly positions background */
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: white;
    padding-top: 0;
    font-family: var(--font-);
}

.kat__footer-links {
    width: 100%;
    display: flex;
    /*Allows responsive space between divs*/
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1rem;
    padding:  0 2rem;
}

.kat__footer-links_contact, 
.kat__footer-links_logo,
.kat__footer-links_work,
.kat__footer-links-quicklinks {
    /* Allows divs to take equal amount of space*/
    flex: 1;
    margin: 1rem;
    text-align: center;
}

.kat__footer-links_work, .kat__footer-links_contact p {
    font-family: var(--font-main);
}

.kat__footer-links-quicklinks > p {
    margin-bottom: 0.5rem; 
}

.kat__footer-links_contact > p {
    margin-bottom: 1rem;
}

.kat__footer-links_logo {
    font-family: var(--font-main);
}

.kat__footer-links_logo img:first-child {
    width: 210px;
    margin-bottom: 0.75rem;
}

/*Add style to every 2nd p tag*/
.kat__footer-links_work p:nth-child(2n + 1) {
    margin-bottom: 1rem;
}

.kat__footer-headtext {
    font-family: var(--font-secondary);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    font-size: 32px;
    line-height: 41.6px;
    margin-bottom: 1rem;
}

.footer__copyright {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: var(--font-secondary);
    background-color: var(--color-bluetext);
    color: white;
    text-align: center;
    
}

@media screen and (min-width: 2000px) {
    .kat__footer-headtext {
        font-size: 51px;
        line-height: 61px;
    }

}

@media screen and (max-width: 1150px) {
    /* align center + flex direction column is optimal for mobile div positioning*/
    .kat__footer-links {
        align-items: center;
        flex-direction: column;
        padding: 0;
    }

    .kat__footer-links_contact, 
    .kat__footer-links_logo,
    .kat__footer-links_work {
       margin: 2rem 0;
       width: 100%; 
    }
}

@media screen and (max-width: 650px) {
    .kat__footer {
        padding:  0 0 2rem 0;
    }
    
}

@media screen and (max-width: 350px) {
    .kat__footer-links_logo img:first-child {
        width: 80%;
    }
    
}