.kat__testimonial-bg {
    background-color: var(--color-bluetext);
}

.kat__testimonial-flexbox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kat__testimonial-title p {
    font-family: var(--font-secondary);
    font-weight: bold;
    font-size: 32px;
    color: white;
}

.kat__testimonial-review-card {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 200px;
    min-height: 400px;
}

.kat__testimonial-review-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    position: relative;
}

.kat__testimonial-review-card-content img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.kat__testimonial-review-description {
    font-size: 15px ;
    font-family: var(--font-tertiary);
    padding-top: 50px;
    font-style: italic;
    color: white;
    /*Change height here for bigger descriptions*/
    min-height: 300px;
}

.kat__testimonial-redline {
    width: 45px;
    border-bottom: 2px solid var(--color-redtext);
    position: absolute;
    bottom: 60px;
}

.kat__testimonial-review-subtitle {
    bottom: 10px;
    position: absolute;
}

.kat__testimonial-review-subtitle p {
    color: white;
    font-family: var(--font-main);
    line-height: 140%;
}

@media screen and (max-width: 1440px ) {
    .kat__testimonial-review-description {
        min-height: 450px;
    }
}

@media screen and (max-width: 1130px ) {
    .kat__testimonial-review-card {
        display: flex;
        flex-direction: column;
    }

    .kat__testimonial-review-description {
        min-height: 215px;
    }
}

@media screen and (max-width: 650px ) {
    .kat__testimonial-title p {
        font-size: 20px;
    }

    .kat__testimonial-review-card-content p {
        font-size: 12px;
    }

    .kat__testimonial-review-description {
        min-height: 200px;
    }
}